
















import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DatePickerBase from '@/components/atoms/DatePickerBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    ButtonBase,
    DatePickerBase,
    InputWithLabel,
  },
})
export default class AccessLog extends Mixins(LocalMoment) {
  private academyCode = ''
  private academyName = ''
  private branchCode = ''
  private branchName = ''
  private from = ''
  private to = ''

  private isProcessing = false

  private async downloadAccsessLog() {
    if (this.isProcessing) return

    const params = []
    if (this.academyCode) params.push(`academyCode=${this.academyCode}`)
    if (this.academyName) params.push(`academyName=${this.academyName}`)
    if (this.branchCode) params.push(`branchCode=${this.branchCode}`)
    if (this.branchName) params.push(`branchName=${this.branchName}`)
    if (this.from) params.push(`from=${this.from}`)
    if (this.to) params.push(`to=${this.to}`)

    await Vue.prototype.$http.httpWithToken
      .get(`/users/loginHistories?${params.join('&')}`, {
        responseType: 'blob',
        dataType: 'binary',
        headers: {
          Accept: 'text/csv',
        },
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'text/plain' })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = `accsess_log_${this.today().format('YYYYMMDDHHmmss')}.csv`
        a.click()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('条件にあうデータが見つかりません。')
        }
      })
      .finally(() => {
        this.isProcessing = false
      })
  }
}
